export default ({ children, content,onClick,className }: {
  children: React.ReactNode,
  content: string | number,
  onClick?: () => void,
  className?: string,
}) => {
  return <div className={`relative w-fit h-fit ${className}`} onClick={onClick} >
    {children}
    {
      +(content ?? 0) > 0 && <div
        className="absolute top-0 right-0 box-content border-[2px] border-[#fff] -translate-y-1/2  translate-x-1/2  px-1 rounded-full  bg-[#F31260]  flex items-center justify-center">
        <div className="text-white text-center text-xs">
          {+(content ?? 0) > 99 ? '99+' : content}
        </div>
      </div>
    }
  </div>;
}