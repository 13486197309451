import NumberInput from '@/components/features/featuresNumberInput';
import { CustomerServiceTypeEnum, FeeTypeEnum } from '@/enums/businessEnum';
import CacheEnum from '@/enums/cacheEnum';
import PageEnum from '@/enums/pageEnum';
import { RechargeTypeEnum } from '@/pages/funds/topUp/useStateHooks';
import { cn } from '@/utils';
import RenderUtil from '@/utils/RenderUtil';
import { ceilNumber, truncateNumber } from '@/utils/number';
import { Button } from '@nextui-org/react';
import { history, useModel } from '@umijs/max';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';
import { useLocation } from '@@/exports';

export default ({ shopInfo }: any) => {
  const { t } = useTranslation();
  const [rechargeNum, setRechargeNum] = useState('');
  // 支付金额
  const paymentAmount = useMemo(() => {
    return +rechargeNum * shopInfo?.price;
  }, [rechargeNum, shopInfo]);
  // 最小
  const min = useMemo(() => {
    return ceilNumber(+shopInfo?.minAmount / shopInfo?.price, 2);
  }, [shopInfo]);
  // 最大
  const max = useMemo(() => {
    return truncateNumber(+shopInfo?.maxAmount / shopInfo?.price, 2);
  }, [shopInfo]);
  // 金额校验
  const checkAmountError = useMemo(() => {
    return +rechargeNum > Number(max) || +rechargeNum < Number(min);
  }, [rechargeNum, min, max]);

  const { isMobile } = useModel('system');
  // 跳转到客服提示
  const { gotoC2CCustomerService } = useModel('imSocket');
  const { state }: any = useLocation();


  return (
    <>
      <div
        className={cn(
          `flex px-2 justify-between h-[48px] items-center rounded-md overflow-hidden bg-backgroundAuxiliaryColor pr-2`,
          {
            'border-1 border-errorColor':
              checkAmountError && rechargeNum !== '',
          },
        )}
      >
        <NumberInput
          className=" overflow-hidden   "
          value={rechargeNum}
          onChange={setRechargeNum}
          placeholder={t('请输入数量')}
          classNames={{
            base: 'rounded-[3px] overflow-hidden h-[40px]',
            input: 'text-[16px]',
            inputWrapper:
              'h-full border-none rounded-[3px]  group-data-[focus=true]:border-backgroundAuxiliaryColor',
          }}
        ></NumberInput>
        <div className="flex items-center gap-1 flex-shrink-0 py-2">
          <div className="text-base">{shopInfo?.coinName}</div>
          <div className="h-[18px] ml-2 mr-[2px] w-[1px] bg-backContrastColor/10"></div>
          <div
            className="text-base text-primary px-3"
            onClick={() => setRechargeNum(max)}
          >
            {t('最大')}
          </div>
        </div>
      </div>
      {/* 提示 */}
      {checkAmountError && rechargeNum !== '' && (
        <>
          <div className="text-errorColor flex items-center gap-2 text-xs -mt-[12px]">
            {min &&
              rechargeNum < min &&
              t('请确保输入金额高于') + (min + shopInfo?.coinName)}
            {max &&
              rechargeNum > max &&
              t('请确保输入金额小于') + (max + shopInfo?.coinName)}
          </div>
        </>
      )}
      <div className="flex items-center gap-2 text-xs text-auxiliaryTextColor -mt-[10px]">
        <span>{t('限额')}</span>
        <div className="flex items-center gap-1">
          <span>
            {RenderUtil.FormatAmount(Number(min))}-
            {RenderUtil.FormatAmount(Number(max))}
          </span>
          <span>{shopInfo?.coinName}</span>
        </div>
      </div>
      <div className="flex items-center justify-between gap-2 text-xs text-auxiliaryTextColor">
        <span className="text-sm">{t('您将支付')}</span>
        <div className="text-sm flex items-center gap-1">
          <span className="text-backContrastColor">
            {RenderUtil.FormatAmount(paymentAmount)}
          </span>
          <span className="text-backContrastColor">
            {shopInfo?.payCoinInfo?.alias}
          </span>
        </div>
      </div>
      {/*<div className="flex items-center justify-between gap-2 text-xs text-auxiliaryTextColor -mt-[4px]">*/}
      {/*  <span className="text-sm">{t('手续费')}</span>*/}
      {/*  <div className="text-sm flex items-center gap-1">*/}
      {/*    <span className="text-backContrastColor">*/}
      {/*      {+shopInfo?.feeType === +FeeTypeEnum.FIXED*/}
      {/*        ? RenderUtil.FormatAmount(shopInfo.fee)*/}
      {/*        : RenderUtil.FormatAmount(*/}
      {/*          (Number(rechargeNum ?? 0) * shopInfo.fee) / 100,*/}
      {/*        )}*/}
      {/*    </span>*/}
      {/*    <span className="text-backContrastColor">{shopInfo?.coinName}</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="fixed w-full bottom-8 left-0 px-4 sm:px-[100px]">
        <Button
          className={cn(
            'bg-[#2DB880]   text-[#fff]  !h-[40px] !rounded-md mx-auto',
            {
              'w-full': isMobile,
              'w-full  max-w-[950px]': !isMobile,
            },
          )}
          onClick={() => {
            if (checkAmountError) {
              return toast.error(t('请输入规定范围内的数量'));
            }
            // 组装提交信息
            const params = {
              ...shopInfo,
              rechargeAmount: rechargeNum,
              pyAmount: truncateNumber(paymentAmount, 2),
              channelId: shopInfo?.id,
              payCoinName: shopInfo?.payCoinInfo?.alias,
              payCoinId: shopInfo?.payCoinInfo?.id,
            };
            localStorage.setItem(
              CacheEnum.APP_RECHARGE_INFORMATION,
              JSON.stringify(params),
            );
            setTimeout(() => {
              gotoC2CCustomerService({
                msg: t('我想要充值') + ' ' + RenderUtil.FormatAmount(paymentAmount) + ' ' + shopInfo?.payCoinInfo?.alias,
                groupType: CustomerServiceTypeEnum.C2C_CUSTOMER_SERVICE,
                channelId: state?.id,
                rechargeType:state?.rechargeType
              });
            });
          }}
        >
          {t('购买')}
        </Button>
      </div>
    </>
  );
};
