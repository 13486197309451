import type { SVGProps } from 'react';

export function MaterialSymbolsHeadphones(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_246_41)">
        <path
          d="M2 10H4C4.53043 10 5.03914 10.2107 5.41421 10.5858C5.78929 10.9609 6 11.4696 6 12V18C6 18.5304 5.78929 19.0391 5.41421 19.4142C5.03914 19.7893 4.53043 20 4 20H2C1.73736 20 1.47728 19.9483 1.23463 19.8478C0.991982 19.7472 0.771504 19.5999 0.585786 19.4142C0.400069 19.2285 0.25275 19.008 0.152241 18.7654C0.0517315 18.5227 0 18.2626 0 18V10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10V18C20 18.2626 19.9483 18.5227 19.8478 18.7654C19.7472 19.008 19.5999 19.2285 19.4142 19.4142C19.2285 19.5999 19.008 19.7472 18.7654 19.8478C18.5227 19.9483 18.2626 20 18 20H16C15.4696 20 14.9609 19.7893 14.5858 19.4142C14.2107 19.0391 14 18.5304 14 18V12C14 11.4696 14.2107 10.9609 14.5858 10.5858C14.9609 10.2107 15.4696 10 16 10H18C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10Z"
          fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_246_41">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
