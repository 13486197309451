import NumberInput from '@/components/features/featuresNumberInput';
import { CustomerServiceTypeEnum, FeeTypeEnum } from '@/enums/businessEnum';
import CacheEnum from '@/enums/cacheEnum';
import PageEnum from '@/enums/pageEnum';
import { RechargeTypeEnum } from '@/pages/funds/topUp/useStateHooks';
import { cn } from '@/utils';
import { truncateNumber } from '@/utils/number';
import RenderUtil from '@/utils/RenderUtil';
import { history } from '@@/core/history';
import { Button } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { Decimal } from 'decimal.js';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';
import { useLocation } from '@@/exports';
import { useLocalStorage } from '@/hooks/useStorage';

export default ({ shopInfo }: any) => {
  const { t } = useTranslation();
  const [rechargeAmount, setRechargeAmount] = useState('');

  const { isMobile } = useModel('system');
  const { state }: any = useLocation();


  // 可得金额
  const getCanGetAmount = useMemo(() => {
    const _rechargeAmount = new Decimal(+(rechargeAmount ?? 0));
    const _price = new Decimal(+(shopInfo?.price ?? 0));
    return _rechargeAmount.div(_price);
  }, [shopInfo, rechargeAmount]);
  // 金额校验
  const checkAmountError = useMemo(() => {
    return (
      +rechargeAmount > +shopInfo?.maxAmount ||
      +rechargeAmount < +shopInfo?.minAmount
    );
  }, [rechargeAmount, shopInfo]);
  // 跳转到客服提示
  const { gotoC2CCustomerService } = useModel('imSocket');


  return (
    <>
      <div
        className={cn(
          'flex px-2 justify-between h-[48px] items-center rounded-md overflow-hidden bg-backgroundAuxiliaryColor pr-2',
          {
            'border-1 border-errorColor':
              checkAmountError && rechargeAmount !== '',
          },
        )}
      >
        <NumberInput
          className=" overflow-hidden   "
          value={rechargeAmount}
          onChange={setRechargeAmount}
          placeholder={t('请输入金额')}
          classNames={{
            base: 'rounded-[3px] overflow-hidden h-[40px]',
            input: 'text-[16px]',
            inputWrapper:
              'h-full border-none rounded-[3px]  group-data-[focus=true]:border-backgroundAuxiliaryColor',
          }}
        ></NumberInput>
        <div className="flex items-center gap-1 flex-shrink-0 py-2">
          <div className="text-base">{shopInfo?.payCoinInfo?.alias}</div>
          <div className="h-[18px] ml-2 mr-[2px] w-[1px] bg-backContrastColor/10"></div>
          <div
            className="text-base text-primary px-3"
            onClick={() => setRechargeAmount(shopInfo?.maxAmount)}
          >
            {t('最大')}
          </div>
        </div>
      </div>
      {/* 提示 */}
      {checkAmountError && rechargeAmount !== '' && (
        <>
          <div className="text-errorColor flex items-center gap-2 text-xs -mt-[12px]">
            {rechargeAmount < shopInfo?.maxAmount &&
              t('请确保输入金额高于') +
              (shopInfo?.minAmount + shopInfo?.payCoinInfo?.alias)}
            {rechargeAmount > shopInfo?.maxAmount &&
              t('请确保输入金额小于') +
              (shopInfo?.maxAmount + shopInfo?.payCoinInfo?.alias)}
          </div>
        </>
      )}
      <div className="flex items-center gap-2 text-xs text-auxiliaryTextColor -mt-[10px]">
        <span>{t('限额')}</span>
        <div className="flex items-center gap-1">
          <span>
            {RenderUtil.FormatAmount(shopInfo?.minAmount)}-
            {RenderUtil.FormatAmount(shopInfo?.maxAmount)}
          </span>
          <span>{shopInfo?.payCoinInfo?.alias}</span>
        </div>
      </div>
      <div className="flex items-center justify-between gap-2 text-xs text-auxiliaryTextColor">
        {/*可得等于 */}
        <span className="text-sm">{t('可得')}</span>
        <div className="text-sm flex items-center gap-1">
          <span className="text-backContrastColor">
            {getCanGetAmount ? RenderUtil.FormatAmount(getCanGetAmount) : 0}
          </span>
          <span className="text-backContrastColor">{shopInfo?.coinName}</span>
        </div>
      </div>

      <div
        className={cn(
          'fixed w-full bottom-8 left-0 px-4 text-center sm:px-[100px]',
          {},
        )}
      >
        <Button
          className={cn(
            'bg-[#2DB880]   text-[#fff]  !h-[40px] !rounded-md mx-auto',
            {
              'w-full': isMobile,
              'w-full  max-w-[950px]': !isMobile,
            },
          )}
          onClick={() => {
            if (checkAmountError) {
              return toast.error(t('请输入规定范围内的金额'));
            }
            // 组装提交信息
            const params = {
              ...shopInfo,
              rechargeAmount: +rechargeAmount
                ? +rechargeAmount / shopInfo?.price
                : 0,
              pyAmount: truncateNumber(+rechargeAmount, 2),
              channelId: shopInfo?.id,
              payCoinName: shopInfo?.payCoinInfo?.alias,
              payCoinId: shopInfo?.payCoinInfo?.id,
            };
            localStorage.setItem(
              CacheEnum.APP_RECHARGE_INFORMATION,
              JSON.stringify(params),
            );
            setTimeout(() => {
              gotoC2CCustomerService({
                msg: t('我想要充值') + ' ' + rechargeAmount + ' ' + shopInfo?.payCoinInfo?.alias,
                groupType: CustomerServiceTypeEnum.C2C_CUSTOMER_SERVICE,
                channelId: state?.id,
                rechargeType:state?.rechargeType
              });
            });
          }}
        >
          {t('购买')}
        </Button>
      </div>
    </>
  );
};
