import { Clock } from '@/assets/icons/comm/Clock';
import { useGlobalPrompt } from '@/components/base/basePromptWindow';
import BaseTabs from '@/components/base/baseTabs';
import BaseTopNav from '@/components/base/baseTopNav';
import ByAmountForm from '@/pages/funds/c2cBuy/components/ByAmountForm';
import ByQuantityForm from '@/pages/funds/c2cBuy/components/ByQuantityForm';
import { toJsonData } from '@/utils/socket';
import { useLocation } from '@@/exports';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MaterialSymbolsHeadphones } from '@/assets/icons/mine/MaterialSymbolsHeadphones';
import { useModel } from '@umijs/max';
import { Badge } from '@nextui-org/react';
import { CustomerServiceTypeEnum } from '@/enums/businessEnum';
import BaseBadge from '@/components/base/baseBadge';

export enum RechargeMethod {
  // 按金额
  Amount = '1',
  // 按数量
  Quantity = '2',
}

export default () => {
  const { t } = useTranslation();
  const { openPrompt } = useGlobalPrompt();
  const [rechargeMethodOptions] = useState([
    {
      text: t('按金额'),
      value: RechargeMethod.Amount,
    },
    {
      text: t('按数量'),
      value: RechargeMethod.Quantity,
    },
  ]);
  const [currentRechargeMethod, setCurrentRechargeMethod] = useState(
    RechargeMethod.Amount,
  );
  const { state }: any = useLocation();


  const findJsonValue = (field: string) => {
    return state?.bankCard
      ? toJsonData(state.bankCard)?.find((it: any) => it.field === field)?.value
      : '';
  };
  const shopInfo = useMemo(() => {
    return {
      ...state,
      businessAvatar: findJsonValue('businessAvatar'), // 商家头像
      merchant: findJsonValue('merchant'), // 商家名称
      volume: findJsonValue('volume'), // 成交量
      orderRate: findJsonValue('orderRate'), // 成单率
      avaCoinReleaseTime: findJsonValue('avaCoinReleaseTime'), // 平均放币时间
      price: state?.rate, // 标价
      quantity: findJsonValue('quantity'), // 数量
    };
  }, [state]);
  const { c2cServiceMessageUNREAD,gotoC2CCustomerService } = useModel('imSocket');


  return (
    <div>
      <BaseTopNav
        title={t('购买{{coinName}}', {
          coinName: shopInfo?.coinName,
        })}
        rightNode={<div className="px-4" onClick={() => {
          gotoC2CCustomerService({
            groupType: CustomerServiceTypeEnum.C2C_CUSTOMER_SERVICE,
            channelId: state?.id,
            rechargeType: state?.rechargeType,
          });
        }}>
          <BaseBadge
            content={+c2cServiceMessageUNREAD[state?.id]}
          >
            <MaterialSymbolsHeadphones
              width={16}
              height={16}
              className=" cursor-pointer   box-content"
            />
          </BaseBadge>
        </div>}
      />
      <div className="px-4">
        <div className="flex items-center gap-4 text-auxiliaryTextColor">
          <div className="flex gap-1 items-center text-xs">
            <span>{t('单价')}</span>
            <span className="pb-[1px]">${shopInfo?.price}</span>
          </div>
          <div
            className="flex gap-1 items-center text-xs"
            onClick={() => {
              openPrompt({
                title: t('提示'),
                content: t('该商家平均在{{avaCoinReleaseTime}}小时内放币', {
                  avaCoinReleaseTime: shopInfo?.avaCoinReleaseTime,
                }),
              });
            }}
          >
            <Clock />
            <span className="border-b-1 border-b-borderColor border-dashed pb-[1px]">
              {shopInfo?.avaCoinReleaseTime}
              H
            </span>
          </div>
        </div>
        <div className="border-1 mt-4 border-borderColor rounded-md pt-2 pb-4 p-4">
          <div className="flex flex-col gap-4">
            <BaseTabs
              value={currentRechargeMethod}
              options={rechargeMethodOptions}
              onChange={setCurrentRechargeMethod}
            />
            {currentRechargeMethod === RechargeMethod.Amount && (
              <ByAmountForm shopInfo={shopInfo} />
            )}
            {currentRechargeMethod === RechargeMethod.Quantity && (
              <ByQuantityForm shopInfo={shopInfo} />
            )}
          </div>
        </div>
        <div className="py-4 flex flex-col gap-4 border-1 border-borderColor rounded-md p-4 mt-3">
          {/* <div className="text-base text-backContrastColor ">{t('商家信息')}</div> */}
          <div className="flex items-center justify-between gap-2 text-xs text-auxiliaryTextColor">
            <span className="text-sm">{t('商家昵称')}</span>
            <div className="flex items-center gap-1 text-sm">
              <img
                src={shopInfo?.businessAvatar}
                className="w-[18px] h-[18px] rounded-full object-cover"
                alt=""
              />
              <span className="text-backContrastColor">
                {shopInfo?.merchant}
              </span>
              <img
                src={require('@/assets/img/shopLogo.png')}
                className="w-[14px] h-[14px] object-cover"
                alt=""
              />
            </div>
          </div>
          {/* 商家说明 */}
          <div className="w-full text-xs text-auxiliaryTextColor flex flex-wrap">
            <span className="text-sm font-medium w-full">{t('商家说明')}</span>
            <div className="w-full text-xs mt-1 whitespace-pre-wrap">
              {shopInfo?.remark ?? t('暂无说明')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
